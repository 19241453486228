/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { colors, device } from '../GlobalStyles';
import styled from '@emotion/styled';
import { navLinks } from './NavLinks';
import { Button } from '../StyledComponents';
import mobile from '../../../images/svgs/mobile.svg';
import marker from '../../../images/svgs/marker.svg';
import arrow from '../../../images/svgs/plus.svg';
import minus from '../../../images/svgs/minus.svg';

interface MenuProps {
    navActive?: boolean;
    dropDownActive: boolean;
}

const StyledNavMenuSlide = styled.div<MenuProps>`
    .spec {
        background: none;
        border: None;
    }
    H6 {
        font-weight: 500;
        letter-spacing: 0.4px;
    }
    width: 100vw;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    top: 0;
    position: fixed;
    transform: ${({ navActive }) => (navActive ? 'translateX(100%)' : 'translateX(000%)')};
    background: ${colors.gradient};
    z-index: 100;
    transform: ${({ navActive }) => (navActive ? 'translateX(0%)' : 'translateX(-100%)')};
    .mobile-nav-buttons {
        display: ${({ dropDownActive }) => (dropDownActive ? 'none' : 'block')};
        img {
            margin-right: 0.5em;
        }
        div {
            font-family: 'Bebas Neue', cursive;
            display: flex;
            font-size: 18px;

            margin-bottom: 1em;
        }
    }
    .nav-contain {
        height: 80vh;
        overflow: scroll;
        margin-top: 6em;
        margin-left: 4em;
    }
    ul {
        margin-bottom: 4em;
        margin-left: 0em;
    }
    li {
        cursor: pointer;
        opacity: ${({ navActive }) => (navActive ? '1' : '0')};
        transition: 1s ease-in;
        letter-spacing: 3.8px;
        color: ${colors.white};
        display: flex;
        list-style: none;
        font-size: 24px;
        border-left: 2px solid ${colors.white};
        margin: 1em 0em;
        padding-left: 1em;
        &:hover {
            text-decoration: underline;
        }
        img {
            margin-left: 1em;
        }
    }
    .services {
        font-size: 18px;
        border-left: none;
        margin: 1em 0em;
        margin-left: 1em;
    }
    .second-list {
        li {
            font-size: 24px;
            padding-left: 0em;
            transition: none;
            &:hover {
                text-decoration: none;
                color: ${colors.primary};
            }
        }
        h2 {
            display: flex;
            align-items: center;
            font-size: 36px;
            border-left: 2px solid ${colors.white};
            margin: 1em 0em;
            padding-left: 0.5em;
            cursor: pointer;
            color: ${colors.white};
            &:hover {
                text-decoration: underline;
            }
            img {
                width: 25px;
                margin-left: 0.2em;
            }
        }
    }
    @media ${device.tablet} {
        ul {
            margin-left: 0em;
        }

        .nav-contain {
            margin-top: 6em;
            margin-left: 2em;
        }
        display: flex;
        justify-content: center;
        li {
            height: 100%;
            letter-spacing: 6.98px;
            font-size: 34px;
        }
    }
    @media ${device.laptop} {
        .second-list {
            h2 {
                font-weight: 300;
                font-size: 46px;
                img {
                    width: 48px;
                    margin-left: 2em;
                }
            }
            position: relative;
            right: 0.5em;
            bottom: 1em;
        }
        .mobile-nav-buttons {
            display: none;
        }
        li {
            font-size: 34px;
        }
    }
    @media ${device.desktop} {
        .second-list {
            h2 {
                padding-left: 0.5em;
                img {
                    margin-left: 125px;
                }
            }
        }
        li {
            letter-spacing: 7.98px;
            font-size: 42px;
        }
    }
`;

const StyledNavButton = styled(Button)<MenuProps>`
    display: ${({ dropDownActive }) => (dropDownActive ? 'none' : 'block')};
    margin-bottom: 1em;
`;

interface Props {
    navActive: boolean;
    setNavActive(arg: boolean): void;
}

export const NavMenuSlide: React.FC<Props> = ({ navActive, setNavActive }) => {
    const [dropDownActive, setDropDownActive] = useState(false);
    return (
        <StyledNavMenuSlide dropDownActive={dropDownActive} navActive={navActive}>
            <div className="nav-contain">
                <ul>
                    {!dropDownActive &&
                        navLinks.map(item => (
                            <>
                                {item.dropdown ? (
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <li
                                        onClick={() => {
                                            setDropDownActive(dropDownActive => !dropDownActive);
                                        }}
                                    >
                                        {item.name} <img src={arrow} alt="arrow icon" />
                                    </li>
                                ) : (
                                    <Link
                                        onClick={() => {
                                            setNavActive(false);
                                            setDropDownActive(false);
                                        }}
                                        key={item.id}
                                        to={item.url}
                                    >
                                        <li>{item.name}</li>
                                    </Link>
                                )}
                            </>
                        ))}
                </ul>
                {dropDownActive && (
                    <ul className="second-list">
                        {navLinks.map(item => (
                            <>
                                {item.dropdown && (
                                    <>
                                        <Link
                                            to="/outpatient"
                                            onClick={() => {
                                                setDropDownActive(
                                                    dropDownActive => !dropDownActive
                                                );
                                            }}
                                        >
                                            <h2>
                                                {item.name} <img src={minus} alt="minus sign" />
                                            </h2>
                                        </Link>
                                        {item.dropdown.map(item => (
                                            <Link
                                                key={item.name}
                                                onClick={() => setNavActive(false)}
                                                to={item.url}
                                            >
                                                <li className="services" key={item.id}>
                                                    {item.name}
                                                </li>
                                            </Link>
                                        ))}
                                    </>
                                )}
                            </>
                        ))}
                    </ul>
                )}
                <Link to="/book-appointment" onClick={() => setNavActive(false)}>
                    <StyledNavButton dropDownActive={dropDownActive} color={`${colors.white}`}>
                        BOOK AN APPOINTMENT
                    </StyledNavButton>
                </Link>
                <div className="mobile-nav-buttons">
                    <div>
                        <img src={mobile} alt="mobile phone" />
                        <a style={{ color: 'white' }} href="tel:8185785125">
                            <h6>(818) 578 5125</h6>
                        </a>
                    </div>
                    <div>
                        <img src={marker} alt="map marker" />
                        <a
                            style={{ color: 'white' }}
                            target="_blank"
                            href="https://www.google.com/maps/place/19228+Ventura+Blvd+a,+Tarzana,+CA+91356,+USA/@52.676695,-7.8065301,14z/data=!3m1!4b1!4m2!3m1!1s0x80c299148b367eb7:0x69910eea314ee511"
                            rel="noreferrer"
                        >
                            <h6>19228 Ventura Blvd, Suite A,Tarzana, CA 91356</h6>
                        </a>
                    </div>
                </div>
            </div>
        </StyledNavMenuSlide>
    );
};
