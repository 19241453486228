import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import arrow from '../images/svgs/black-arrow.svg';

interface Props {
    services: Service[];
    type: string;
}

interface Service {
    path: string;
    title: string;
}

const StyledList = styled.ul`
    a {
        color: black;
    }
    h4 {
        letter-spacing: 3px;
        font-family: 'Bebas Neue', cursive;
    }
    .item {
        cursor: pointer;
        font-size: 24px;
        padding-bottom: 0.5em;
        list-style: none;
        border-bottom: 2px solid #000000;
        margin: 2em 0em;
        margin-right: 2.5em;
        display: flex;
        font-family: 'Bebas Neue', cursive;
        position: relative;
        img {
            position: absolute;
            right: 0.2em;
        }
    }
`;

export const ServiceTypes: React.FC<Props> = ({ services, type }) => {
    return (
        <StyledList>
            <h4>{type}</h4>
            <ul>
                {services.map(service => (
                    <Link key={service.path} to={`/${service.path}`}>
                        <li className="item">
                            {service.title}
                            <img src={arrow} alt="arrow sign" />
                        </li>
                    </Link>
                ))}
            </ul>
        </StyledList>
    );
};
