import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';

type Props = {
    color: string;
};

export const Button = styled.button<Props>`
    background: rgba(255, 255, 255, 0.2);
    padding: 0.5rem 1rem;
    font-size: 18px;
    cursor: pointer;
    border: 4px solid ${colors.primary};
    line-height: 28px;
    backdrop-filter: blur(10px);
    font-family: 'Bebas Neue';
    color: ${props => props.color};
    text-decoration: uppercase;
    &:hover {
        background: none;
    }
`;

export const LogoSquare = styled.img`
    background: ${colors.secondary};
    padding: 1em;
    width: 125.19px;
    @media ${device.tablet} {
        padding: 2em;
        width: 157.19px;
    }
`;

export const Template = styled.section`
    background: #f8f8f8f8;
    padding: 1em;
    position: relative;
    .special-image {
        max-width: 200px;
        margin: 0 auto;
    }
    h2 {
        margin-top: 1.5em;
    }
    h4 {
        color: ${colors.primary};
        position: relative;
        top: 1em;
    }
    .neograft-image-container {
        .neograft-left-image,
        .neograft-right-image {
            margin: 2em 0em;
        }
    }
    .cellfina-container {
        img {
            margin: 1em 0em;
        }
    }
    .featured-image {
        z-index: 3;
    }
    .neograft-iframe-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        iframe {
            margin: 1em 0em;
        }
    }
    .special-video {
        display: flex;
        justify-content: center;
    }
    h1 {
        margin-top: 2.5em;
        margin-bottom: 0.75em;
    }
    h3 {
        margin: 1.5em 0em 1em;
    }
    li {
        font-weight: 500;
        font-family: 'Roboto';
        margin: 1em 1em;
    }
    .marbel-cube {
        display: none;
    }
    .container {
        z-index: 2;
    }
    a {
        font-family: 'Roboto';
        color: ${colors.primary};
        font-weight: 700;
    }
    .marbel {
        display: none;
    }
    p {
        margin: 0.75em 0em;
    }
    .image-container {
        position: relative;
        display: flex;
        justify-content: center;
        .featured-image {
            width: 350px;
            height: 200px;
        }
        .logo {
            z-index: 4;
            position: absolute;
            margin: 0 auto;
            bottom: -3em;
        }
    }
    .fat-reduction-image {
        margin: 2em 0em;
    }
    @media ${device.tablet} {
        .cellfina-container {
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            width: 185vw;
            img {
                margin: 0em 2em;
            }
        }
        .neograft-iframe-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            iframe {
                width: 582px;
                height: 329px;
            }
        }
        .neograft-image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .neograft-left-image,
            .neograft-right-image {
                margin: 2em 0em;
            }
        }
        .fat-reduction-image {
            margin: 2em auto;
            width: 576px;
            height: 383.25px;
        }
        .special-video {
            iframe {
                margin: 1em;
                width: 471px;
                height: 263px;
            }
        }
        position: relative;
        .marbel-container {
            display: flex;
            flex-direction: column;
            padding-bottom: 4em;
        }
        .marbel {
            align-self: center;
            display: block;
            position: absolute;
            bottom: -18em;
            z-index: 0;
            width: 472px;
            height: 400px;
        }
        .marbel-cube {
            display: block;
            position: absolute;
            right: 1em;
            width: 155px;
            top: -65px;
        }
        padding: 2em 2em 8em;
        .image-container {
            .featured-image {
                width: 500px;
                height: 300px;
            }
        }
    }
    @media ${device.laptop} {
        .cellfina-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            img {
                width: 75vw;
                margin: 1.5em 0em;
            }
        }
        .special-image {
            max-width: 300px;
            margin: 0 auto;
        }
        .image-container {
            .featured-image {
                width: 650px;
                height: 400px;
            }
        }
    }
    @media ${device.laptopL} {
        .cellfina-container {
            display: flex;
            flex-direction: row;
            justify-content: center;

            margin: 0 auto;
            img {
                width: 384px;
                margin: 0em 1em;
            }
        }
        .neograft-iframe-container {
            flex-direction: row;
        }
        .neograft-image-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .neograft-left-image,
            .neograft-right-image {
                margin: 2em 1em;
            }
            .neograft-left-image {
                width: 502px;
                height: auto;
            }
            .neograft-right-image {
                width: 698px;
                height: auto;
            }
        }
        .fat-reduction-image {
            margin: 2em auto;
            width: 768px;
            height: 511px;
        }
        .special-video {
            iframe {
                width: 571px;
                height: 323px;
            }
        }
        .marbel-cube {
            width: 236px;
        }
    }
    @media ${device.desktop} {
        .special-image {
            max-width: 400px;
            margin: 0 auto;
        }
        padding: 2em 0em 6em;
        .marbel-cube {
            width: 226px;
            right: 1em;

            top: -145px;
        }
        .image-container {
            .featured-image {
                width: 1200px;
                height: 600px;
            }
        }
    }
    @media ${device.desktopL} {
        .marbel-cube {
            width: 256px;
            right: 1em;
            top: -145px;
        }
    }
`;
