import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import marbelAlt from '../images/marbel-alt.jpg';
import { Button } from './layout/StyledComponents';

const StyledGallery = styled.section`
    .pic-container {
        overflow-x: scroll;
        .pic-row {
            width: 1200px;
            overflow-y: hidden;
            display: flex;
            flex-wrap: wrap;
            .pic {
                margin: 0.5em 0.5em;
                width: 378px;
                height: 283px;
            }
        }
    }
    .marbel {
        padding: 2em 1em 5em;
        background: url(${marbelAlt});
        h2 {
            padding: 0em 0em 1em;
        }
        p {
            margin-bottom: 2em;
        }
    }
    @media ${device.laptopL} {
        display: flex;
        .marbel {
            width: 50vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 1em;
            padding: 0em 4em;
        }
    }
    @media ${device.desktop} {
        h2 {
            margin-bottom: 0.5em;
        }
        button {
            margin-top: 1em;
        }
        .m {
            margin-top: 1em;
        }
        .pic-container {
            overflow-y: scroll;
            max-height: 100vh;
            .pic-row {
                overflow-x: hidden;
                max-width: 900px;
                .pic {
                    width: 283px;
                    height: 378px;
                }
            }
        }
    }
`;

export const HomeGallery: React.FC = () => {
    return (
        <StyledGallery>
            <div className="pic-container">
                <div className="pic-row">
                    <StaticImage
                        className="pic"
                        src="../images/gallery/hallway.jpg"
                        alt="hallway"
                    />

                    <StaticImage
                        className="pic"
                        src="../images/gallery/paper-chair.jpg"
                        alt="chair with paper"
                    />
                    <StaticImage
                        className="pic"
                        src="../images/gallery/advertisement-girl.jpg"
                        alt="add of a girl with makeup"
                    />
                </div>
                <div className="pic-row">
                    <StaticImage
                        className="pic"
                        src="../images/gallery/screen.jpg"
                        alt="machine with screen"
                    />
                    <StaticImage
                        className="pic"
                        src="../images/gallery/white-chair-turned.jpg"
                        alt="patient chair turned to the side"
                    />
                    <StaticImage className="pic" src="../images/gallery/doctor.jpg" alt="doctor" />
                </div>
                <div className="pic-row">
                    <StaticImage
                        className="pic"
                        src="../images/gallery/patient-chair.jpg"
                        alt="medical exam chair"
                    />
                    <StaticImage
                        className="pic"
                        src="../images/gallery/waiting-room.jpg"
                        alt="elegant waiting room"
                    />
                    <StaticImage
                        className="pic"
                        src="../images/gallery/white-chair.jpg"
                        alt="white chair in bright light"
                    />
                </div>
            </div>
            <div className="marbel">
                <h2>GALLERY</h2>
                <p>A friendly, comfortable environment with an industry leading staff.</p>
                <Link to="/book-appointment">
                    <Button color={colors.primary}>BOOK AN APPOINTMENT</Button>
                </Link>
            </div>
        </StyledGallery>
    );
};
