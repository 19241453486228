exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-book-appointment-tsx": () => import("./../../../src/pages/book-appointment.tsx" /* webpackChunkName: "component---src-pages-book-appointment-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-doctors-tsx": () => import("./../../../src/pages/our-doctors.tsx" /* webpackChunkName: "component---src-pages-our-doctors-tsx" */),
  "component---src-pages-outpatient-tsx": () => import("./../../../src/pages/outpatient.tsx" /* webpackChunkName: "component---src-pages-outpatient-tsx" */),
  "component---src-pages-patient-resources-tsx": () => import("./../../../src/pages/patient-resources.tsx" /* webpackChunkName: "component---src-pages-patient-resources-tsx" */),
  "component---src-templates-about-template-tsx": () => import("./../../../src/templates/about-template.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-services-template-tsx": () => import("./../../../src/templates/services-template.tsx" /* webpackChunkName: "component---src-templates-services-template-tsx" */)
}

