import * as React from 'react';
import { graphql } from 'gatsby';
//components
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Hero } from '../components/Hero';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { LogoSquare, Template } from '../components/layout/StyledComponents';
import logo from '../images/tarzana-colored-logo.png';
import marbel from '../images/marbel-markdown.jpg';
import marbelCube from '../images/marbel-markdown-cube.jpg';
import { Seo } from '../components/Seo';

const StyledTemplate = styled(Template)``;

interface Props {
    mdx: {
        frontmatter: {
            title: string;
            heroTitle: string;
            seoTitle: string;
            seoDescription: string;
            heroImage: {
                childImageSharp: {
                    gatsbyImageData: string;
                };
            };
            featuredImage: {
                childImageSharp: {
                    gatsbyImageData: string;
                };
            };
        };
        body: string;
    };
}

const AboutUs: Page<Props> = ({ data }) => {
    const { mdx } = data;
    return (
        <>
            <Seo title={mdx.frontmatter.seoTitle} description={mdx.frontmatter.seoDescription} />
            <Hero
                heroImage={mdx.frontmatter.heroImage.childImageSharp.gatsbyImageData}
                title={mdx.frontmatter.heroTitle}
            />
            <StyledTemplate>
                <img className="marbel-cube" src={marbelCube} alt="cubic shaped marbel panel" />
                <div className="container">
                    <div className="image-container">
                        <GatsbyImage
                            className="featured-image"
                            alt="girl after breast implants"
                            image={mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                        />
                        <LogoSquare className="logo" src={logo} alt="company logo" />
                    </div>
                    <div className="marbel-container">
                        <h1>{mdx.frontmatter.title}</h1>
                        <MDXRenderer>{mdx.body}</MDXRenderer>
                        <img
                            className="marbel"
                            src={marbel}
                            alt="marbel half-hidden bottom panel"
                        />
                    </div>
                </div>
            </StyledTemplate>
        </>
    );
};
export default AboutUs;

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                heroTitle
                slug
                featuredImage {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 800
                            aspectRatio: 1.4
                            placeholder: BLURRED
                            quality: 90
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                heroImage {
                    name
                    childImageSharp {
                        gatsbyImageData(width: 2000, quality: 90, webpOptions: { quality: 90 })
                    }
                }
            }
            body
            id
        }
    }
`;

AboutUs.Layout = DefaultLayout;
