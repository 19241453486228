import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { device, colors } from './layout/GlobalStyles';
import { Button } from './layout/StyledComponents';
import marbel from '../images/services-marbel.png';
import arrow from '../images/svgs/black-arrow.svg';

interface ServicesPanelProps {
    homepage?: boolean;
}

const StyledServicesPanel = styled.section<ServicesPanelProps>`
    position: relative;
    padding-left: 1em;
    margin-bottom: 3em;
    padding-top: 2em;
    flex-direction: column;
    align-items: center;
    .marbel {
        background: url(${marbel});
        background-repeat: no-repeat;
        position: absolute;
        top: ${({ homepage }) => (homepage ? '0em' : '50em')};
        z-index: -1;
        width: ${({ homepage }) => (homepage ? '100vw' : '60vw')};
        right: 0em;
        height: 150vh;
    }
    .image-container {
        width: 360px;
        position: relative;
    }
    .balloon {
        border: 8px solid #ffffff;
        width: 350px;
        height: 290px;
        z-index: 1;
    }
    .fit-girl {
        border: 8px solid #ffffff;
        z-index: 1;
        width: 189px;
        height: 172px;
    }
    .lip-injection {
        border: 8px solid #ffffff;
        width: 165px;
        height: 217px;
        z-index: 2;
        position: relative;
        bottom: 2.8em;
        right: 0.3em;
    }
    h2 {
        z-index: 1;
    }
    li {
        cursor: pointer;
        font-size: 24px;
        padding-bottom: 0.5em;
        list-style: none;
        border-bottom: 2px solid #000000;
        margin: 2em 0em;
        margin-right: 2.5em;
        display: flex;
        position: relative;
        img {
            position: absolute;
            right: 0em;
        }
    }
    button {
        margin-top: 1em;
    }
    @media ${device.tablet} {
        margin-bottom: 0em;
        padding: 4em 0em 3em;

        .flex {
            justify-content: space-around;
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        .image-container {
            width: 607px;
            margin: 0 auto;
        }
        .balloon {
            width: 598px;
            height: 395px;
        }
        .fit-girl {
            width: 323px;
            height: 293px;
        }
        .lip-injection {
            width: 283px;
            height: 370px;
            right: 0em;
            right: 0.5em;
            bottom: 4.8em;
        }
        .marbel {
            width: 342px;
            left: 0em;
            height: 60%;
        }
    }
    @media ${device.laptop} {
        .marbel {
            width: 202px;
        }
        .list-flex {
            display: flex;
            justify-content: space-between;
            width: 700px;
        }
        li {
            width: ${({ homepage }) => (homepage ? '530px' : '340px')};
        }
    }
    @media ${device.laptopL} {
        .marbel {
            height: 100%;
        }
        .flex {
            flex-direction: ${({ homepage }) => (homepage ? 'row' : 'column')};
        }
    }
    @media ${device.desktop} {
        li {
            width: ${({ homepage }) => (homepage ? '600px' : '340px')};
        }
    }
`;

const ServicesPanelButton = styled(Button)``;

interface Props {
    homepage?: boolean;
    title?: string;
}

export const ServicesPanel: React.FC<Props> = ({ title, homepage }) => {
    return (
        <StyledServicesPanel homepage={homepage}>
            <div className="marbel"></div>
            <div className="flex">
                <div>
                    <div className="image-container">
                        <StaticImage
                            className="balloon"
                            src="../images/knee-check.jpg"
                            alt="hands holding knee"
                        />
                        <StaticImage
                            className="fit-girl"
                            src="../images/sick-guy.jpg"
                            alt="guy about to vomit"
                        />
                        <StaticImage
                            className="lip-injection"
                            src="../images/eye-girl.jpg"
                            alt="girl getting eye examined"
                        />
                    </div>
                </div>
                <div>
                    {title ? <h1>{title}</h1> : <h2>{`OUTPATIENT SERVICES`}</h2>}

                    <div className="list-flex">
                        <ul>
                            <Link to="/surgical/ear-nose-and-throat">
                                <li>
                                    EAR NOSE AND THROAT (ENT)
                                    <img src={arrow} alt="arrow sign" />
                                </li>
                            </Link>
                            <Link to="/surgical/gastroenterology-surgery/">
                                <li>
                                    GASTROENTEROLOGY SURGERY
                                    <img src={arrow} alt="arrow sign" />
                                </li>
                            </Link>
                            <Link to="/surgical/neurosurgery/">
                                <li>
                                    NEUROSURGERY
                                    <img src={arrow} alt="arrow sign" />
                                </li>
                            </Link>
                            {!homepage && (
                                <>
                                    <Link to="/surgical/ear-nose-and-throat">
                                        <li>
                                            EAR NOSE AND THROAT (ENT)
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                    <Link to="/surgical/gastroenterology-surgery/">
                                        <li>
                                            GASTROENTEROLOGY SURGERY
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                    <Link to="/surgical/neurosurgery/">
                                        <li>
                                            NEUROSURGERY
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                    <Link to="/surgical/gynecology/">
                                        <li>
                                            GYNECOLOGY
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                    <Link to="/surgical/orthopedic/">
                                        <li>
                                            ORTHOPEDIC
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                        <ul>
                            {!homepage && (
                                <>
                                    <Link to="/surgical/pain-management/">
                                        <li>
                                            PAIN MANAGEMENT
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                    <Link to="/surgical/plastic-reconstructive/">
                                        <li>
                                            PLASTIC SURGERY
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                    <Link to="/surgical/podiatric/">
                                        <li>
                                            PODIATRIC SURGERY
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                    <Link to="/surgical/erectile-dysfunction/">
                                        <li>
                                            ERECTILE DYSFUNCTION
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                    <Link to="/aesthetics/neograft-hair-restoration/">
                                        <li>
                                            HAIR RESTORATION
                                            <img src={arrow} alt="arrow sign" />
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>
                    {homepage && (
                        <Link to="/outpatient">
                            <ServicesPanelButton color={colors.primary}>
                                SEE ALL Services
                            </ServicesPanelButton>
                        </Link>
                    )}
                </div>
            </div>
        </StyledServicesPanel>
    );
};
