import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { device, colors } from './layout/GlobalStyles';
import { LogoSquare, Button } from './layout/StyledComponents';
import logo from '../images/tarzana-colored-logo.png';
import blueMarbel from '../images/marbel.jpg';

const StyledOurDoctors = styled.section`
    background: #f8f8f8;
    padding: 6em 1em 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .blue-marbel {
        top: 4em;
        right: 0em;
        position: absolute;
        background: url(${blueMarbel});
        width: 195px;
        height: 195px;
    }

    .image-container {
        position: relative;
        .logo {
            position: relative;
            bottom: 4em;
            margin: 0 auto;
        }
    }
    p {
        margin: 2em 0em 1em;
    }
    h2 {
        align-self: flex-start;
        font-size: 48px;
        margin-left: 0em;
    }
    button {
        align-self: flex-start;
    }
    .doctor-image {
        width: 320px;
    }
    @media ${device.tablet} {
        .blue-marbel {
            display: none;
        }
        .doctor-image {
            width: 450px;
        }
    }

    @media ${device.laptop} {
        display: flex;
        flex-direction: row-reverse;
        padding: 4em 4em 0em;
        .left-column {
            padding: 2em;
        }
        .right-column {
            margin-left: 50px;
        }
        .blue-marbel {
            display: block;
            top: 10em;
            width: 400px;
            height: 465px;
        }
        .left-column {
            margin-bottom: 4em;
        }
    }
    @media ${device.laptopL} {
        .right-column {
            margin-right: 2em;
        }
        .doctor-image {
            width: 500px;
        }
    }
    @media ${device.desktop} {
        .doctor-image {
            width: 600px;
        }
        .blue-marbel {
            top: 10em;
            width: 400px;
            height: 465px;
        }
        .left-column {
            margin-bottom: 0em;
        }
        p {
            margin-bottom: 4em;
        }
        .right-column {
            margin-left: 130px;
        }
        .doctor-image {
            width: 600px;
            height: 678px;
        }
    }
`;

const DoctorButton = styled(Button)``;

export const OurDoctors: React.FC = () => {
    return (
        <StyledOurDoctors>
            <div className="right-column">
                <div className="blue-marbel"></div>
                <div className="image-container">
                    <StaticImage
                        className="doctor-image"
                        src="../images/team-cropped.jpg"
                        alt="cropped group shot of doctors"
                    />
                    <LogoSquare className="logo" src={logo} alt="logo" />
                </div>
            </div>
            <div className="left-column">
                <h2>OUR DOCTORS</h2>
                <p>
                    Our team of physicians and cosmetic professionals are excited to share with you
                    our customized and unique approach to beauty and wellness with our unparalleled
                    state-of-the-art technology and individualized care.
                </p>
                <p>
                    Our skilled team of professional aestheticians and physicians are focused on
                    you. When you visit Vytal Aesthetics, we provide personalized care to help you
                    achieve your aesthetic goals, and work hard to ensure that you can enjoy
                    beautiful, long-lasting results. Each member of our team continues to pursue
                    advanced training, ensuring that we remain knowledgeable and up-to-date on the
                    best practices so that we can give you the highest quality of care.
                </p>
                <Link to="/our-doctors">
                    <DoctorButton color={colors.black}>LEARN MORE</DoctorButton>
                </Link>
            </div>
        </StyledOurDoctors>
    );
};
