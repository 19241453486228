import { Nav } from '../components/layout/nav/Nav';
import { Footer } from '../components/layout/footer/Footer';
import { ThemeProvider } from '@emotion/react';
import { GlobalStyles, device, colors } from '../components/layout/GlobalStyles';
import silhouette from '../images/svgs/silhouette.svg';
import styled from '@emotion/styled';

const Img = styled.img`
    display: none;
    position: absolute;
    top: 170vh;
    z-index: 10;
    right: 4em;
    opacity: 0.6;
    @media ${device.laptop} {
        top: 120vh;
        z-index: 0;
        display: block;
        width: 280px;
    }
    @media ${device.laptopL} {
        width: 280px;
        z-index: 1;
    }
    @media ${device.desktop} {
        width: 370px;
        top: 130vh;
        z-index: 1;
    }
`;

export const DefaultLayout: React.FC = ({ children }) => {
    return (
        <div id="layout">
            <ThemeProvider theme={colors}>
                <GlobalStyles />
                <Nav />
                <Img className="silhouette" src={silhouette} alt="silhouette of a girl" />
                {children}
                <Footer />
            </ThemeProvider>
        </div>
    );
};
