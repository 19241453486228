import { graphql } from 'gatsby';
import React from 'react';
import { Hero } from '../components/Hero';
import { Seo } from '../components/Seo';
import styled from '@emotion/styled';
import { device, colors } from '../components/layout/GlobalStyles';
import logo from '../images/vytal-logo.png';
import { StaticImage } from 'gatsby-plugin-image';
import marbelCube from '../images/marbel-markdown-cube.jpg';
import { LogoSquare, Template } from '../components/layout/StyledComponents';

interface Props {
    heroImage: {
        edges: {
            node: {
                childImageSharp: {
                    gatsbyImageData: string;
                };
            };
        }[];
    };
}

const StyledTemplate = styled(Template)`
    .image-container {
        margin-top: 3.5em;
    }
    h2 {
        margin-top: 1em;
    }
    @media ${device.laptop} {
        .reverse {
            flex-direction: row-reverse;
        }
        .container {
            display: flex;
            p {
                z-index: 400;
                margin-bottom: 1.5em;
            }
            .image-container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .featured-image {
                    width: 300px;
                    margin: 0em 2em;
                    height: 300px;
                }

                .logo {
                    z-index: 4;
                    position: absolute;
                    margin: 0 auto;
                    bottom: -3em;
                }
            }
        }
    }
`;

const OurDoctors: Page<Props> = ({ data }) => {
    const heroImage = data.heroImage.edges[0].node.childImageSharp.gatsbyImageData;
    return (
        <>
            <Seo
                title="Our Doctors – vytalcenter"
                description="Meet the doctors of Tarzana, California"
            />
            <Hero heroImage={heroImage} title="OUR DOCTORS" />
            <main>
                <StyledTemplate>
                    <h1 style={{ textAlign: 'center' }}>Meet Our Doctors</h1>
                    <img className="marbel-cube" src={marbelCube} alt="cubic shaped marbel panel" />
                    {/* NEW DOCTOR STARTS HERE. uncomment below */}
                    <div className="reverse container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctor-about.jpeg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Fardin Hakakian, D.P.M. - Podiatrist</h2>
                            <p>
                                Dr. Fardin Hakakian, D.P.M., is a top-rated podiatrist providing
                                skilled, compassionate care for patients in and around Tarzana, CA.
                                At his practice on Wilbur Avenue, Dr. Hakakian offers the most
                                advanced nonsurgical and surgical treatments for an array of foot-
                                and ankle-related issues, including ingrown toenails, toenail
                                fungus, bunions, corns and calluses, and heel pain. The practice is
                                equipped with the latest technology for treatments that are safe,
                                effective, and customizable for each patient’s unique needs,
                                including laser systems for toenail fungus treatment, minimally
                                invasive bunion surgery and ingrown toenail surgery procedures, PRP
                                injections for soft tissue and traumatic injuries, and sports
                                medicine treatments for athletes of all levels.
                            </p>
                            <p>
                                Dr. Hakakian earned his medical degree at California College of
                                Podiatric Medicine followed by a residency at LAC/USC Medical
                                Center. As a leading podiatrist in Tarzana, CA, he has extensive
                                experience in foot and ankle surgeries using both traditional and
                                minimally invasive techniques for optimal outcomes and a fast return
                                to mobility and improved quality of life. Dr. Hakakian is board
                                certified by the American Board of Lower Extremity Surgery, a
                                diplomate of the American Board of Disability Analysts and a fellow
                                of the American Professional Wound Care Association. He welcomes the
                                opportunity to care for new patients and the practice accepts most
                                major medical insurance plans.
                            </p>
                            <a href="https://toesandmore.com/">www.toesandmore.com</a>
                        </div>
                    </div>
                    <div className=" container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-jason-khadavi.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Jason Khadavi - Podiatrist</h2>
                            <p>
                                Dr. Khadavi is a Los Angeles native who earned his undergraduate
                                degree at the University of California, Los Angeles (UCLA). He went
                                on to attend the California School of Podiatric Medicine in Oakland,
                                California, and then completed a three-year surgical residency at
                                St. Mary’s Medical Center in San Francisco, California. During his
                                podiatry training and residency, Dr. Khadavi focused heavily on foot
                                and ankle surgery, with a particular emphasis on innovative
                                solutions that restore patient mobility with less downtime.
                            </p>
                            <a href="https://www.trufootandankle.com">www.trufootandankle.com</a>
                        </div>
                    </div>

                    {/* NEW DOCTOR STARTS HERE. uncomment below */}
                    <div className="reverse container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-greg-khounganian.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Greg Khounganian - Orthopedic Surgeon</h2>
                            <p>
                                Dr. Greg Khounganian is board certified in spine surgery that
                                emphasizes minimally invasive techniques. After earning his medical
                                degree from the University of Southern California, he completed his
                                orthopedics surgery residency in New York City. As he grew adept at
                                treating a variety of orthopedic problems, ranging from sports
                                injuries to broken bones to arthritic joint conditions, Dr.
                                Khounganian developed an interest in spine and spine-related
                                disorders.
                            </p>
                            <a href="https://www.gkspine.com">www.gkspine.com</a>
                        </div>
                    </div>
                    {/* NEW DOCTOR STARTS HERE. uncomment below */}

                    <div className=" container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-vlad-gendelman.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Vlad Gendelman - Orthopedic Surgeon</h2>
                            <p>
                                Dr. Vlad Gendelman received his Bachelor of Science in Biology at
                                the University of Southern California and received his Doctorate
                                from University of California at Irvine. Dr. Gendelman went on to
                                complete his residency in Orthopedic Surgery at SUNY Downstate in
                                Brooklyn, NY. During his time in Brooklyn, NY he has served as a
                                team physician of Sheepshead Bay High School Varsity and Junior
                                Varsity Football Teams. Dr. Gendelman is licensed to practice
                                medicine in the state of California, and is board certified with
                                American Board of Orthopedic Surgery. Dr. Gendelman holds membership
                                in various professional organizations, including the Los Angeles
                                County Medical Association, the California Medical Association,
                                California Orthopedic Association and the American Academy of
                                Orthopedic Surgery.
                            </p>
                            <a href="https://www.urgentorthopaediccare.com/">
                                www.urgentorthopaediccare.com
                            </a>
                        </div>
                    </div>

                    {/* NEW DOCTOR STARTS HERE. uncomment below */}

                    <div className="reverse container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-jejuda-renan.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Jejuda Renan - Obstetrician, Gynecologist (OBGYN)</h2>
                            <p>
                                Dr. Jehuda Renan is an OB-GYN treating patients in Tarzana,
                                California, and the surrounding communities. His goal as a
                                practitioner is to ensure that each of his patients receives the
                                highest available standard of medical care. He holds certification
                                from the American Board of Obstetrics and Gynecology and is
                                affiliated with facilities including the Providence Tarzana Medical
                                Center and the Providence Saint Joseph Medical Center. In order to
                                pursue his medical degree, Dr. Renan attended the Tel Aviv
                                University Sackler School of Medicine. He continued his medical
                                education at Chaim Sheba Medical Center and Cedars Sinai Medical
                                Center, where he received residency training in general surgery.
                                Dr.Renan has also completed a residency program in obstetrics and
                                gynecology at the University of Massachusetts Medical School.
                                Patients visiting Dr. Renan can receive a full range of obstetric
                                and gynecological services. He provides Pap smear procedures, annual
                                gynecological examinations, osteoporosis evaluations, menopause
                                consultations, contraception, and general consultations.
                            </p>
                            <a href="mailto: drjejudarenan">drjejudarenan@gmail.com</a>
                        </div>
                    </div>

                    {/* NEW DOCTOR STARTS HERE. uncomment below */}
                    <div className=" container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-julio-lemus.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Julio Lemus - Obstetrician, Gynecologist (OBGYN)</h2>
                            <p>
                                {`Dr. Julio Lemus' mission is to provide the best care that he can to
                                his patients. He is Board Certified by the American Board of
                                Obstetrics and Gynecology and Member of the American College of
                                Obstetrics and Gynecology.`}
                            </p>
                            <p>
                                He had his Internship in Obstetrics and Gynecology at UCLA School of
                                Medicine and his residency training in OBGYN at Pennsylvania State
                                University. He then became a faculty at the University of Miami and
                                UCLA School of Medicine. ﻿ He has additional training in General
                                Surgery from Stanford University and the University of California
                                San Francisco. He has done a Research Fellowship in Critical Care at
                                Cedars Sinai Research Institute in Los Angeles. He has also
                                developed computer programs applied to Medicine and has conducted
                                research, publications and lectures in the areas of Infertility,
                                Cervical Cancer, Endometriosis, Chronic Pelvic Pain, Active
                                Management of Labor, Intra-partum Monitoring, Critical Care and many
                                other subjects
                            </p>
                            <a href="http://juliolemusmd.com/">www.juliolemusmd.com</a>
                        </div>
                    </div>

                    {/* NEW DOCTOR STARTS HERE. uncomment below */}
                    <div className="reverse container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-gary-ott.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Gary Ott - Board Certified Physician </h2>
                            <p>
                                With over 20 years of experience, Board-Certified physician Gary D.
                                Ott, MD, is located in Brentwood, Los Angeles as well as West Hills,
                                CA.
                            </p>
                            <p>
                                He specializes in Hormone - Restoration and Youthful - Aging,
                                changing the lives of both Men and Women. His treatment programs are
                                designed for increased energy, strength, and weight loss. Patients
                                return to us, reporting better moods, memory, and metal clarity. His
                                patients experience restored libido, improved relationships, and
                                enjoy feeling younger, healthier and happier again.
                            </p>
                            <a href="https://www.premierhealthassoc.com/">
                                www.premierhealthassoc.com
                            </a>
                        </div>
                    </div>
                    {/* NEW DOCTOR STARTS HERE. uncomment below */}

                    <div className=" container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-farhad-rafii.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Farhad Rafii - Cardiologist </h2>
                            <p>
                                Clinical Assistant Professor of Medicine, Keck School of Medicine of
                                the University of Southern California. Volunteer Faculty. Clinical
                                Assistant Professor of Internal Medicine, Western University of
                                Health Sciences/College of Osteopathic Medicine of the Pacific
                            </p>
                            <p>
                                Dr. Rafii joined Interventional Cardiology Medical Group in August
                                2013. He holds five board certifications and is a Diplomate of the
                                American Board of Internal Medicine, Board of Cardiovascular
                                Diseases, the National Board of Echocardiography, International
                                Board of Heart Rhythm Examiners, and the Certification Board of
                                Nuclear Cardiology.
                            </p>
                            <p>
                                Dr. Rafii has co-authored and published multiple manuscripts and
                                original research articles in major cardiovascular journals. His
                                clinical interests include prevention and treatment of coronary
                                artery disease and heart attacks, heart failure, disorders of heart
                                valves and heart rhythm, cholesterol abnormalities, hypertension,
                                and heart disease in women.
                            </p>
                            <a href="https://www.icardiomg.com/farhad-rafii">www.icardiomg.com</a>
                        </div>
                    </div>

                    {/* NEW DOCTOR STARTS HERE. uncomment below */}

                    <div className="reverse container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-shankar-lakshman.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Shankar Lakshman - Plastic Surgeon</h2>
                            <p>
                                Dr. Lakshman is a board certified plastic surgeon and is also board
                                certified in surgery of the hand. He began his training in one of
                                the busiest trauma centers in the nation working for three years in
                                pre-requisite general surgery residency. Afterward, Dr. Lakshman
                                worked for a year and half with a burn and wound care fellowship at
                                the same institution. During this time, he learned to resuscitate
                                burn victims and treat the terrible, painful and disfiguring scars
                                caused by burns. He spent countless days and nights in the Burn ICU
                                with patients suffering from post-burn infections and inhalational
                                injury. Burn surgery is a challenging and rewarding field but it is
                                24 hour, 7 days a week commitment requiring ‘round the clock patient
                                care. This intense experience in the surgical and ICU management of
                                the most challenging and life threatening conditions experienced by
                                surgical patients gave Dr. Lakshman a level of confidence and
                                surgical skill that he now brings to every client in his plastic
                                surgery practice.
                            </p>
                            <a href="https://www.pasadenasurgeons.com">www.pasadenasurgeons.com</a>
                        </div>
                    </div>

                    {/* NEW DOCTOR STARTS HERE. uncomment below */}

                    <div className=" container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-samuel-kashani.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Samuel Kashani - General Surgery, Bariatric</h2>
                            <p>
                                Samuel Kashani, MD is a board-certified general and bariatric
                                surgeon. He has an extensive background in bariatric surgery, as
                                well as gastrointestinal, hernia and cancer surgery. His expertise
                                includes using minimally invasive Single Port techniques
                                laparoscopically in any of the above surgeries as well as weight
                                loss procedures performed at the Bariatric Surgery Center of
                                Excellence Dr. Kashani’s serves as the current Chief of Surgery at
                                Sherman Oaks Hospital, is an Assistant Clinical Professor at UCLA
                                School of Nursing, is a general surgeon at Wound Care Center at
                                Valley Presbyterian Hospital, and has been running his own private
                                practice since 2008 on the Westside and San Fernando Valley.
                            </p>
                            <p>
                                Dr. Kashani completed his Doctor of Medicine as well as a Master of
                                Science degree (Applied Physiology) at Rosalind Franklin University
                                in 2002. He then completed his internship and residency in general
                                surgery at UCSF – East Bay Surgery Program, when he volunteered at
                                the Guatemala Surgical Mission. He also completed a fellowship in
                                minimally invasive, advanced laparoscopic, and bariatric surgery at
                                Cedars– Sinai Medical Center. Dr. Kashani is board certified in
                                General Surgery by the American Board of Surgery.
                            </p>
                            <a href="http://drsamkashani.com/">www.drsamkashani.com</a>
                        </div>
                    </div>

                    {/* NEW DOCTOR STARTS HERE. uncomment below */}

                    <div className="reverse container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-shervin-aminpour.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>
                                Dr. Shervin Aminpour - Facial Plastic Surgeon, Ear, Nose, & Throat{' '}
                            </h2>
                            <p>
                                As a board-certified facial plastic and reconstructive surgeon, Dr.
                                Shervin Aminpour is one of the most qualified ENT specialists in Los
                                Angeles. In the interest of providing the best facial plastic
                                surgery Los Angeles has available, Dr. Aminpour constantly provides
                                his patients with the most up to date care available by staying
                                abreast with advancements in his field. In addition to his two
                                private practices in the San Fernando Valley where he expertly
                                treats patients with a wide assortment of conditions, Dr. Aminpour
                                ensures that he expands his technique and expertise as a facial
                                plastic surgeon by maintaining a membership on prominent medical
                                boards and societies.
                            </p>
                            <a href="https://www.hillsaesthetics.com/">www.hillsaesthetics.com</a>
                        </div>
                    </div>

                    {/* NEW DOCTOR STARTS HERE. uncomment below */}

                    <div className=" container">
                        <div className="image-container">
                            <StaticImage
                                className="featured-image"
                                src="../images/doctors/dr-obaida-batal.jpg"
                                alt="frontal profile of doctor"
                            />
                        </div>

                        <div className="marbel-container">
                            <h2>Dr. Obaida Batal - Gastroenterologist, General Surgeon</h2>
                            <p>
                                Dr. Obaida Batal is a general surgeon in Anaheim, California. He
                                received his medical degree from University of Aleppo Faculty of
                                Medicine and has been in practice between 6-10 years
                            </p>
                            <a href="mailto: batal.obaida@gmail.com">batal.obaida@gmail.com</a>
                        </div>
                    </div>
                </StyledTemplate>
            </main>
        </>
    );
};

export const query = graphql`
    query {
        heroImage: allFile(filter: { relativePath: { eq: "team-cropped.jpg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(width: 2000, quality: 90, webpOptions: { quality: 90 })
                    }
                }
            }
        }
    }
`;
export default OurDoctors;
