import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import React from 'react';
import { css } from '@emotion/react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { device, colors } from './layout/GlobalStyles';
import logo from '../images/tarzana-colored-logo.png';
import { Button } from './layout/StyledComponents';
import marbel from '../images/marbel.jpg';

const HomeStaffStyled = styled.section`
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 1em;
    position: relative;
    .marbel {
        position: absolute;
        z-index: 1;
        width: 195px;
        height: 195px;
        top: 2em;
        left: -1em;
    }
    .image-logo-container {
        z-index: 2;
        position: relative;
        margin: 0 auto;
    }
    .image {
        width: 310px;
    }
    .logo {
        width: 207.19px;
        padding: 2em;
        background: ${colors.secondary};
        position: absolute;
        top: 19em;
        left: 5em;
        margin: 0 auto;
    }
    p {
        margin: 1em 0em;
    }
    h1 {
        margin-top: 2.5em;
    }
    button {
        margin-top: 1em;
    }
    @media ${device.tablet} {
        padding: 6em 1em;
        h1 {
            margin-bottom: 1em;
        }
        .text {
            padding: 0em 2em;
        }
        .logo {
            top: 28em;
            left: 0em;
            right: 0em;
            margin: 0 auto;
        }
        .marbel {
            top: 4em;
            width: 315px;
            height: 305px;
        }
    }
    @media ${device.laptop} {
        .marbel {
            left: 2em;
            width: 315px;
            height: 305px;
        }
        .text {
            padding: 0em 6em;
        }
        .logo {
            top: 30.5em;
        }
    }
    @media ${device.laptopL} {
        flex-direction: row;
        align-items: center;
        .text {
            margin-top: 1em;
            h1 {
                margin-top: 0em;
            }
            padding: 0em 4em;
        }
        .logo {
            top: 88%;
        }
    }
    @media ${device.desktop} {
        .marbel {
            left: 0em;
        }
        padding: 10.5em 0;

        .logo {
            top: 32em;
        }
        .text {
            padding: 0em 8em;
        }
    }
    @media ${device.desktopL} {
        .logo {
            top: 32em;
        }
        .text {
            padding: 0em 8em;
        }
    }
`;

const StyledImage = styled(GatsbyImage)`
    @media (max-width: 767px) {
        width: 310px;
        height: 350px;
    }
    @media (min-width: 768px) {
        width: 681px;
        height: 505px;
    }
    @media (min-width: 1023px) {
        width: 781px;
        height: 545px;
    }
    @media (min-width: 1280px) {
        width: 601px;
        height: 445px;
    }
    @media (min-width: 1480px) {
        width: 721px;
        height: 525px;
        margin-left: 4em;
    }
    @media (min-width: 1680px) {
        width: 751px;
        height: 565px;
        margin-left: 6em;
    }
`;

export const HomeStaff: React.FC = () => {
    const data = useStaticQuery(graphql`
        query {
            desktop: allFile(filter: { relativePath: { eq: "home-staff-cropped.jpg" } }) {
                edges {
                    node {
                        id
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            mobile: allFile(filter: { relativePath: { eq: "home-staff-mobile.jpg" } }) {
                edges {
                    node {
                        id
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `);
    const { mobile, desktop } = data;

    const desktopImage = getImage(desktop.edges[0].node.childImageSharp.gatsbyImageData);
    const mobileImage = getImage(mobile.edges[0].node.childImageSharp.gatsbyImageData);

    const images =
        desktopImage &&
        mobileImage &&
        withArtDirection(desktopImage, [
            {
                media: '(max-width: 767px)',
                image: mobileImage,
            },
        ]);

    return (
        <HomeStaffStyled>
            <img className="marbel" src={marbel} alt="marbel pattern" />
            <div className="image-logo-container">
                {images && <StyledImage alt="group photo of the dentist team" image={images} />}
                <img className="logo" src={logo} alt="logo" />
            </div>
            <div className="text">
                <h1>Medical Center in Tarzana California</h1>
                <p>
                    We offer a comprehensive range of first class cosmetic surgeries and modern
                    non-surgical treatments to patients. You can rest assured that Vytal Surgical
                    Institute is registered, and undergoes regular inspections, by the care quality
                    commission.
                </p>
                <p>
                    Our team of dedicated health professionals are here to serve you. We combine
                    extensive knowledge in the field of surgery with a passion for helping people
                    solve their health problems. It is our goal to create a friendly and
                    professional atmosphere that you can rely on for all of your health needs.
                </p>
                <Link to="/about-us">
                    <Button color={`${colors.black}`}>LEARN MORE</Button>
                </Link>
            </div>
        </HomeStaffStyled>
    );
};
