import React, { useCallback, useState } from 'react';
//styles
import { Button } from '../components/layout/StyledComponents';
import { colors, device } from './layout/GlobalStyles';
import styled from '@emotion/styled';
import markerBlack from '../images/svgs/marker-black.svg';
import clockBlack from '../images/svgs/clock-black.svg';
import mobileBlack from '../images/svgs/mobile-black.svg';

const appointmentText = [
    `Please use this form for general information purposes only. DO NOT send personal health information through this form. Specific patient care must be addressed during your appointment`,
    `Please complete the following form to request an appointment. Please also note that availability will vary depending on your request. Your appointment will be confirmed by phone by a member of our staff. Thank you!`,
];
const contactText = `Please use this form for general information purposes only. DO NOT send personal health information through this form. Specific patient care must be addressed during your appointment.`;

const BG = styled.div`
    background: #f8f8f8f8;
`;
const StyledMapPanel = styled.section`
    padding: 0em 1em;

    h1 {
        padding-top: 2em;
    }
    h2 {
        margin-bottom: 1em;
    }
    p {
        margin-bottom: 4em;
    }
    h6 {
        font-size: 21px;
        line-height: 25px;
        font-weight: 500;
        text-align: center;
        font-family: 'Bebas Neue', cursive;
    }
    .phone {
        position: relative;
        bottom: 0.8em;
    }
    .phone,
    .map {
        color: ${colors.primary};
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .white-panel {
        position: relative;
        bottom: 4em;
        background: #f2f2f2;
        margin: 0 auto;
        width: 292px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1em 2em 2em;
        div {
            text-align: center;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                max-width: 55px;
                margin: 1em 0em 0.5em;
            }
        }
    }
    form {
        div {
            margin: 2em 0em;
            display: flex;
            flex-direction: column;
        }
        input,
        textarea {
            margin: 0.5em 0em;
            font-size: 18px;
            border: 1px solid black;
            padding: 1em;
            font-family: 'Roboto';
        }
        label {
            font-family: 'Bebas Neue';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 155.7%;
        }
    }
    button {
        margin: 1em 0em 4em;
    }

    @media ${device.laptop} {
        .mobile {
            padding: 1em;
        }
        .name-phone-flex {
            display: flex;
            flex-direction: row;
            input {
                margin-right: 3em;
            }
        }
        input {
            width: 300px;
        }
        textarea {
            color: grey;
            width: 487px;
        }
        .white-panel {
            bottom: 14em;
            left: 10em;
            width: 814px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            div {
                margin: 0 2em;
                max-width: 250px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
`;
const MapContainer = styled.div`
    height: 50vh;
    margin: 2em -1em;
    iframe {
        filter: invert(85%);
    }
    @media ${device.tablet} {
        iframe {
            width: 720px;
            height: 720px;
            margin-bottom: -5em;
        }
        margin-top: 1em;
        height: 50%;
        width: 50%;
    }
`;

interface Props {
    appointment?: boolean;
}

export const MapPanel: React.FC<Props> = ({ appointment }) => {
    const [submitState, setSubmitState] = useState<string>();

    const onSubmit = useCallback(
        async e => {
            e.preventDefault();
            setSubmitState('submitting');

            const data = Object.fromEntries(new FormData(e.target));

            await fetch(`https://api.${window.location.host}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            setSubmitState('submitted');

            e.target.reset();
        },
        [setSubmitState]
    );

    return (
        <BG>
            <StyledMapPanel className="container">
                <h1>{appointment ? 'APPOINTMENT' : 'CONTACT US'}</h1>
                <p>{appointment ? `${appointmentText[0]} ${appointmentText[1]}` : contactText}</p>
                <form onSubmit={onSubmit}>
                    <div className="name-phone-flex">
                        <div>
                            <label htmlFor="name">FULL NAME</label>
                            <input id="name" name="name" type="text" placeholder="full name" />
                        </div>
                        <div>
                            <label htmlFor="phone">PHONE</label>
                            <input
                                id="phone"
                                name="phone"
                                type="tel"
                                placeholder="(000) 000 0000"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email">EMAIL</label>
                        <input name="email" id="email" type="email" placeholder="email@email.com" />
                    </div>
                    <div>
                        <label htmlFor="message">BODY</label>
                        <textarea
                            id="message"
                            rows={6}
                            name="message"
                            placeholder="please include any notes for the doctor or staff"
                        ></textarea>
                    </div>
                    {submitState === 'submitted' ? (
                        <>
                            <h3>
                                Message sent! Somebody from our office will be in touch shortly.
                            </h3>
                            <br />
                            <br />
                            <br />
                            <br />
                        </>
                    ) : (
                        <Button disabled={submitState === 'submitting'} color={colors.black}>
                            {submitState === 'submitting'
                                ? 'SENDING...'
                                : appointment
                                ? 'BOOK AN APPOINTMENT'
                                : 'CONTACT US'}
                        </Button>
                    )}
                </form>
                <MapContainer>
                    <iframe
                        title="map for vytal aesthetics"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3300.9852329501364!2d-118.55418834941793!3d34.172295218746264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2990aab176f1b%3A0x889a93a3437065f6!2sVytal%20Center!5e0!3m2!1sen!2sus!4v1635859587240!5m2!1sen!2sus"
                        width="100%"
                        allowFullScreen
                        loading="lazy"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        aria-hidden="false"
                    />
                </MapContainer>
                <div className="white-panel">
                    <a
                        target="_blank"
                        href="https://www.google.com/maps/place/19228+Ventura+Blvd+a,+Tarzana,+CA+91356,+USA/@52.676695,-7.8065301,14z/data=!3m1!4b1!4m2!3m1!1s0x80c299148b367eb7:0x69910eea314ee511"
                        rel="noreferrer"
                    >
                        <div className="phone">
                            <img className="mobile" src={mobileBlack} alt="telephone icon" />
                            <h6>(818) 578 5125</h6>
                        </div>
                    </a>
                    <a
                        target="_blank"
                        href="https://www.google.com/maps/place/19228+Ventura+Blvd+a,+Tarzana,+CA+91356,+USA/@52.676695,-7.8065301,14z/data=!3m1!4b1!4m2!3m1!1s0x80c299148b367eb7:0x69910eea314ee511"
                        rel="noreferrer"
                    >
                        <div className="map">
                            <img
                                style={{ color: 'black' }}
                                className="marker"
                                src={markerBlack}
                                alt="map marker icon"
                            />
                            <h6>
                                19228 Ventura Blvd, Suite <br />
                                A,Tarzana, CA 91356
                            </h6>
                        </div>
                    </a>
                    <div>
                        <img className="clock" src={clockBlack} alt="clock icon" />
                        <h6>Monday - Friday: 8:00 am- 5:00 pm saturday: appointments only</h6>
                    </div>
                </div>
            </StyledMapPanel>
        </BG>
    );
};
