import { MapPanel } from '../components/MapPanel';
import { Hero } from '../components/Hero';
import { graphql } from 'gatsby';
import { Seo } from '../components/Seo';

interface Props {
    heroImage: {
        edges: {
            node: {
                childImageSharp: {
                    gatsbyImageData: string;
                };
            };
        }[];
    };
}

const ContactUs: Page<Props> = ({ data }) => {
    const heroImage = data.heroImage.edges[0].node.childImageSharp.gatsbyImageData;
    return (
        <main>
            <Seo
                title="Book Appointment – vytalcenter"
                description="Book an Appointment with Vytal Center in Tarzana California"
            />
            <Hero heroImage={heroImage} title="BOOK AN APPOINTMENT" />
            <MapPanel appointment />
        </main>
    );
};

export const query = graphql`
    query {
        heroImage: allFile(filter: { relativePath: { eq: "hero/girl-on-phone.jpeg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(width: 2000, quality: 90, webpOptions: { quality: 90 })
                    }
                }
            }
        }
    }
`;

export default ContactUs;
