import { graphql } from 'gatsby';
import { Hero } from '../components/Hero';
import { ServicesPanel } from '../components/ServicesPanel';
import { Seo } from '../components/Seo';

interface Props {
    allFile: {
        edges: {
            node: {
                childImageSharp: {
                    gatsbyImageData: string;
                };
            };
        }[];
    };
}

const Outpatient: Page<Props> = ({ data }) => {
    const heroImage = data.allFile.edges[0].node.childImageSharp.gatsbyImageData;

    return (
        <main>
            <Seo
                title="Outpatient Services in Tarzana CA"
                description="Offering various outpatient services for men and women with focus on surgical operations and recovery."
            />
            <Hero title="SERVICES" heroImage={heroImage} />
            <ServicesPanel title={`Outpatient Services In Tarzana California`} />
        </main>
    );
};

export const query = graphql`
    query {
        allFile(filter: { relativePath: { eq: "hero/chair-cropped.jpeg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(width: 2000, quality: 90, webpOptions: { quality: 90 })
                    }
                }
            }
        }
    }
`;
export default Outpatient;
