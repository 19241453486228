import { css, Global } from '@emotion/react';
import React from 'react';

export const colors = {
    grey: '#171B24',
    black: 'black',
    white: '#FFFFFF',
    primary: '#D29028',
    secondary: '#EED5B1',
    gradient: 'linear-gradient(321.57deg, #421000 0%, #BC8E47 144.15%)',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '768px',
    laptop: '1020px',
    laptopL: '1280px',
    desktop: '1540px',
    desktopL: '1920px',
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`,
};

export const GlobalStyles: React.FC = () => {
    return (
        <Global
            styles={css`
                html {
                    box-sizing: border-box;
                    font-size: 100%;
                    overflow-x: hidden;
                    -ms-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    scroll-behavior: smooth;
                }
                body {
                    background: ${colors.white};
                    font-weight: normal;
                    font-size: 1rem;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    margin: 0;
                    -webkit-font-smoothing: antialiased;
                }
                * {
                    margin: 0;
                    transition: 0.2s all;
                }
                .container {
                    max-width: 1200px;
                    margin: 0 auto;
                }
                h1,
                h2 {
                    font-weight: 500;
                    color: ${colors.grey};
                    letter-spacing: 6.84px;
                    font-size: 36px;
                    line-height: 48px;
                    font-family: 'Bebas Neue', cursive;
                }
                h3 {
                    font-size: 24px;
                    font-family: 'Roboto';
                }
                p {
                    font-family: 'Roboto';
                    font-size: 18px;
                    line-height: 28.03px;
                }
                a {
                    text-decoration: none;
                    color: black;
                }
                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                }
                ul {
                    padding-left: 0;
                }
                li {
                    z-index: 3;
                    font-family: 'Bebas Neue', cursive;
                }
                button,
                input,
                optgroup,
                select,
                textarea {
                    margin: 0;
                }
                button,
                input {
                    font-family: 'Bebas Neue';
                    overflow: visible;
                }
                ::-webkit-input-placeholder {
                    color: inherit;
                    opacity: 0.54;
                }
                ::-webkit-file-upload-button {
                    -webkit-appearance: button;
                    font: inherit;
                }
                @media ${device.laptop} {
                    h1,
                    h2 {
                        font-size: 48px;
                        line-height: 62px;
                    }
                    h3 {
                        font-size: 24px;
                        font-family: 'Roboto';
                    }
                }
                @media ${device.desktop} {
                    h3 {
                        font-size: 26px;
                    }
                    h2 {
                        font-size: 48px;
                        line-height: 62px;
                    }
                }
                @media ${device.desktopL} {
                    h1,
                    h2 {
                        font-size: 52px;
                        line-height: 77px;
                        letter-spacing: 3px;
                    }
                    p {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    h3 {
                        font-size: 28px;
                        font-family: 'Roboto';
                    }
                }
            `}
        />
    );
};
